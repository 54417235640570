import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { updateOrganisation, userInfo } from "../../apiFunction/ApiFunctions";
import { useLocation } from "react-router-dom";
import HeaderComponent from "../admin/shared/HeaderComponent";
import FooterComponent from "../admin/shared/FooterComponent";

const EditOrganization = () => {
  const location = useLocation();
  const { state } = location;
  const env = process.env.REACT_APP_ENV;
  const [isLoading, setIsLoading] = useState(false);
  const userOrganisationDetails = JSON.parse(
    sessionStorage.getItem("organisationDetails")
  )[0];
  // const [isEditable, setIsEditable] = useState(false);
  const [organisationName, setOrganisationName] = useState(
    userOrganisationDetails.name
  );
  const [organisationType, setOrganisationType] = useState(
    userOrganisationDetails.type
  );
  const [organisationUrl, setOrganisationUrl] = useState(
    userOrganisationDetails.website_url
  );
  const [address, setAddress] = useState(userOrganisationDetails.address);
  const [phone, setPhone] = useState(userOrganisationDetails.phone_number);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  //   const user = user;

  // function enableEdit() {
  //   setIsEditable(true);
  // }

  const updateOrganisationDetails = () => {
    setError(null);
    setIsLoading(true);
    const organisation = {
      id: userOrganisationDetails.id,
      name: organisationName,
      type: organisationType,
      address: address,
      phone_number: phone,
      website_url: organisationUrl,
      stripe_id: userOrganisationDetails.stripe_id,
      image: "",
    };

    updateOrganisation(organisation)
      .then(async (response) => {
        setIsLoading(false);
        if (response.ok) {
          const responseData = await response.json();
          console.log("organisation", responseData);
          sessionStorage.setItem(
            "organisationDetails",
            JSON.stringify([responseData])
          );
        } else {
          console.log(await response.text());
          setError("Failed to Update Organisation");
        }
      })
      // .then((data) => {
      //   console.log("organisation", data);
      //   sessionStorage.setItem("organisationDetails", JSON.stringify([data]));
      //   setIsLoading(false);
      // })
      .catch((error) => {
        console.error("Error updating organisation:", error);
      });
  };

  return (
    <div className="min-h-screen bg-white">
      <HeaderComponent />
      <div className="container mx-auto p-6">
        <div className="flex mb-8">
          <img
            src={`https://66-dementia.b-cdn.net/${env}/organisations/images/${
              userOrganisationDetails.id
            }?${new Date().getTime()}`}
            alt={organisationName}
            className="w-32 h-32 rounded-full mr-6"
          />
          <div>
            <h2 className="text-3xl text-customBlue mt-12">
              {organisationName}
            </h2>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-8 ml-40">
          <div>
            <label className="block mb-2">Organisation Name</label>
            <input
              type="text"
              value={organisationName}
              onChange={(e) => setOrganisationName(e.target.value)}
              className="w-full border p-2 rounded"
              // readOnly={!isEditable}
            />
          </div>
          <div>
            <label className="block mb-2">Organisation Type</label>
            <select
              value={organisationType}
              onChange={(e) => {
                setOrganisationType(e.target.value);
              }}
              className="w-full border p-2 rounded bg-white"
            >
              <option value="NHS Trust">NHS Trust</option>
              <option value="Care Home">Care Home</option>
              <option value="Local Authority">Local Authority</option>
              <option value="Service Provider">Service Provider</option>
            </select>
            {/* <input
              type="text"
              value={organisationType}
              onChange={(e) => setOrganisationType(e.target.value)}
              className="w-full border p-2 rounded"
            /> */}
          </div>
          <div>
            <label className="block mb-2">Address</label>
            <input
              type="text"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              className="w-full border p-2 rounded"
            />
          </div>
          <div>
            <label className="block mb-2">Phone Number</label>
            <input
              type="text"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              className="w-full border p-2 rounded"
            />
          </div>
          <div>
            <label className="block mb-2">Website</label>
            <input
              type="text"
              value={organisationUrl}
              onChange={(e) => setOrganisationUrl(e.target.value)}
              className="w-full border p-2 rounded"
              // readOnly={!isEditable}
            />
            <div className="flex justify-center gap-2 mt-8">
              <button
                className="bg-gray-600 p-2 text-white rounded-lg w-16"
                onClick={() => {
                  updateOrganisationDetails();
                }}
              >
                {!isLoading && "Save"}
                {isLoading && (
                  <div role="status" className="ml-[20%]">
                    <svg
                      aria-hidden="true"
                      className="h-8 w-8 animate-spin fill-blue-600 text-gray-200 dark:text-gray-600"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                    <span className="sr-only">Loading...</span>
                  </div>
                )}
              </button>
            </div>
            {error && (
              <div
                className="bg-red-100 border border-red-400 text-red-700 px-2 py-3 rounded relative w-1/3 ml-[33.33%] text-center mt-2"
                role="alert"
              >
                <span className="block  sm:inline">Error : {error}</span>
              </div>
            )}
          </div>
          {/* <div>
            <label className="block mb-2">Phone</label>
            <input
              type="text"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              className="w-full border p-2 rounded"
              readOnly={!isEditable}
            />
          </div> */}
        </div>

        <FooterComponent path={-1} />
      </div>
    </div>
  );
};

export default EditOrganization;
