// import React from "react";
// import { useLocation, useNavigate } from "react-router-dom";
// import training from "../../images/activetraining/1.png";
// import specialisttraining from "../../images/activetraining/2.png";
// import advancedtraining from "../../images/activetraining/3.png";
// import HeaderComponent from "../admin/shared/HeaderComponent";
// import Header from "./Header";

// const CourseCard = ({
//   id,
//   course_id,
//   course_name,
//   course_description,
//   percent_complete,
//   navigate,
// }) => (
//   <div className="bg-white p-4 rounded-lg shadow-lg">
//     <img
//       src={`https://66-dementia.b-cdn.net/${env}/courses/images/${course_id}`}
//       alt={course_name}
//       className="w-full h-56 object-cover rounded-lg mb-4"
//     />
//     <div className=" flex flex-col ">
//       <div className="">
//         <div className="h-1/5">
//           <h2 className="text-xl font-semibold mb-2">{course_name}</h2>
//         </div>
//         <div className="flex flex-col items-center">
//           <div className="w-full bg-gray-300 rounded-full h-6 mb-2">
//             <div
//               className="bg-[#0089ad] h-6 rounded-full"
//               style={{ width: `${percent_complete}%` }}
//             ></div>
//           </div>
//           <p className="text-gray-700 mb-4">
//             Completion Rate: {Math.round(percent_complete)}%
//           </p>
//         </div>
//         <ul className="text-gray-700 text-sm mb-4">
//           {course_description
//             .split(".")
//             .filter((item) => item !== "")
//             .map((item, index) => (
//               <li key={index} className="mb-1">
//                 * {item}
//               </li>
//             ))}
//         </ul>
//       </div>
//       <div className="">
//         <button
//           className="bg-blue-900 mx-[35%] text-white px-10 py-2 rounded-3xl shadow-lg hover:bg-blue-700"
//           onClick={() => {
//             navigate("/coursedetails", {
//               state: {
//                 courseName: course_name,
//                 userCourseId: id,
//                 courseId: course_id,
//               },
//             });
//           }}
//         >
//           View
//         </button>
//       </div>
//     </div>
//   </div>
// );

// const ActiveTrainingCourses = () => {
//   const navigate = useNavigate();
//   const location = useLocation();
//   const { state } = location;
//   return (
//     <div className="min-h-screen bg-white ">
//       <div className="container mx-auto px-4">
//         <HeaderComponent />
//         <h2 className="text-2xl font-semibold text-gray-700 mb-6">
//           Active Training Courses
//         </h2>
//         <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-20">
//           {state.courses.map((course, index) => (
//             <CourseCard key={index} {...course} navigate={navigate} />
//           ))}
//         </div>
//         <div className="mt-8 mb-4 flex justify-center">
//           <button
//             className="bg-blue-900 text-white px-10 py-2 rounded-3xl shadow-lg hover:bg-blue-700"
//             onClick={() => {
//               navigate(-1);
//             }}
//           >
//             Back
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ActiveTrainingCourses;

import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import HeaderComponent from "../admin/shared/HeaderComponent";
const CourseCard = ({
  id,
  course_id,
  course_name,
  course_description,
  percent_complete,
  navigate,
  env
}) => (
  <div className="bg-white p-4 rounded-lg shadow-lg flex flex-col">
    <img
      src={`https://66-dementia.b-cdn.net/${env}/courses/images/${course_id}?${new Date().getTime()}`}
      alt={course_name}
      className="w-full h-56 object-cover rounded-lg mb-4"
    />
    <div className="flex flex-col flex-grow">
      {/* Course Name */}
      <h2 className="text-xl font-semibold mb-2">{course_name}</h2>
      {/* Progress Bar */}
      <div className="flex flex-col items-center mb-4">
        <div className="w-full bg-gray-300 rounded-full h-6">
          <div
            className="bg-[#0089ad] h-6 rounded-full"
            style={{ width: `${percent_complete}%` }}
          ></div>
        </div>
        <p className="text-gray-700 mt-2">
          Completion Rate: {Math.round(percent_complete)}%
        </p>
      </div>
      {/* Course Description */}
      <ul className="text-gray-700 text-sm mb-4 flex-grow">
        {course_description
          .split(".")
          .filter((item) => item !== "")
          .map((item, index) => (
            <li key={index} className="mb-1">
              * {item}
            </li>
          ))}
      </ul>
      {/* View Button */}
      <div className="mt-auto mb-2">
        <button
          className="bg-blue-900 text-white px-10 py-2 rounded-3xl shadow-lg hover:bg-blue-700 w-full"
          onClick={() => {
            navigate("/coursedetails", {
              state: {
                courseName: course_name,
                userCourseId: id,
                courseId: course_id,
              },
            });
          }}
        >
          View
        </button>
      </div>
    </div>
  </div>
);

const ActiveTrainingCourses = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const env = process.env.REACT_APP_ENV;
  return (
    <div className="min-h-screen bg-white">
      <HeaderComponent />
      <div className="container mx-auto px-4">
        <h2 className="text-2xl font-semibold text-gray-700 mb-6">
          Active Training Courses
        </h2>
        {/* Grid of Courses */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10">
          {state.courses.map((course, index) => (
            <CourseCard key={index} {...course} navigate={navigate} env={env}/>
          ))}
        </div>
        {/* Back Button */}
        <div className="mt-8 mb-2 flex justify-center">
          <button
            className="bg-blue-900 text-white px-10 py-2 rounded-3xl shadow-lg hover:bg-blue-700"
            onClick={() => navigate(-1)}
          >
            Back
          </button>
        </div>
      </div>
    </div>
  );
};

export default ActiveTrainingCourses;
