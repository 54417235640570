import React, { useEffect, useState } from "react";
import { getWebinars } from "../../apiFunction/ApiFunctions";
import { useLocation, useNavigate } from "react-router-dom";
import HeaderComponent from "../admin/shared/HeaderComponent";
import FooterComponent from "../admin/shared/FooterComponent";

const UpcomingTrainingWebinars = () => {
  const location = useLocation();
  const { state } = location;
  const env = process.env.REACT_APP_ENV;
  const [webinars, setWebinars] = useState(state.webinars);
  const navigate = useNavigate();
  const auth = JSON.parse(sessionStorage.getItem("userdetails"));

  // useEffect(() => {
  //   const sessionWebinarData = JSON.parse(
  //     sessionStorage.getItem("webinarData")
  //   );
  //   console.log(sessionWebinarData);
  //   const upcomingWebinarList = getWebinars();

  //   // Only add sessionTrainingVideo if it is valid
  //   if (sessionWebinarData) {
  //     console.log([...upcomingWebinarList, ...sessionWebinarData]);
  //     setWebinars([...sessionWebinarData, ...upcomingWebinarList]);
  //   } else {
  //     setWebinars(upcomingWebinarList);
  //   }
  // }, []);

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col">
      <HeaderComponent />

      <main className="w-full px-6 flex flex-col items-center">
        <div className="w-full max-w-8xl rounded-lg px-8 py-4 space-y-6 ml-4 ">
          <div className="flex justify-between items-center">
            <h2 className="text-2xl text-customBlue ml-[-1%]">
              Upcoming Training Webinars
            </h2>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-16">
            {webinars.length > 0 &&
              webinars.map((webinar, index) => (
                <div
                  key={webinar.id}
                  className="bg-white rounded-lg shadow-lg p-4 flex flex-col justify-between"
                >
                  <div>
                    <img
                      src={`https://66-dementia.b-cdn.net/${env}/webinars/images/${webinar.id}`}
                      alt={webinar.title}
                      className="w-full h-48 object-cover rounded-lg mb-4"
                    />
                    <div className="text-center mb-6">
                      <h3
                        className="text-xl text-customBlue mb-2 cursor-pointer"
                        // onClick={() =>
                        //   navigate("/webinarregistrationlist", {
                        //     state: {
                        //       id: webinar.id,
                        //       title: webinar.title,
                        //       subtitle: webinar.description,
                        //       presentedBy: webinar.presenter,
                        //       date: new Date(
                        //         webinar.date_time
                        //       ).toLocaleDateString(),
                        //       time: webinar.date_time,
                        //     },
                        //   })
                        // }
                      >
                        {webinar.title}
                      </h3>
                      <p className="italic">{webinar.description}</p>
                    </div>
                    <div className="space-y-2">
                      <p>Presented By: {webinar.presenter}</p>
                      <p>
                        Time: {new Date(webinar.date_time).toLocaleDateString()}{" "}
                        -{" "}
                        {new Date(webinar.date_time).getHours() < 10
                          ? "0" + new Date(webinar.date_time).getHours()
                          : new Date(webinar.date_time).getHours()}
                        :
                        {new Date(webinar.date_time).getMinutes() < 10
                          ? "0" + new Date(webinar.date_time).getMinutes()
                          : new Date(webinar.date_time).getMinutes()}
                      </p>
                      <p>Staff Registered: {webinar.registered_users}</p>
                    </div>

                    <div className="w-full mt-4 flex justify-around">
                      <button
                        className=" bg-blue-950 text-white py-2 px-6 rounded-2xl hover:bg-blue-700"
                        onClick={() =>
                          navigate("/webinarregistrationlist", {
                            state: {
                              id: webinar.id,
                              title: webinar.title,
                              subtitle: webinar.description,
                              presentedBy: webinar.presenter,
                              date: new Date(
                                webinar.date_time
                              ).toLocaleDateString(),
                              time: webinar.date_time,
                            },
                          })
                        }
                      >
                        View
                      </button>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </main>

      <FooterComponent path={-1} />
    </div>
  );
};

export default UpcomingTrainingWebinars;
