import HeaderComponent from "./shared/HeaderComponent";
import dementia from "../../images/strees&behaviour.png";
import behaviouralChange from "../../images/changes_in_behaviour.png";
import stressAndBehaviour from "../../images/dementia.png";
import play from "../../images/play_button.jpeg";
import React, { useEffect } from "react";
import FooterComponent from "./shared/FooterComponent";
import { useNavigate } from "react-router-dom";
import VideoModal from "./shared/VideoModalComponent";
import { useState } from "react";
import localVideo from "../../videos/sample.mp4";
import moment from "moment/moment";
import {
  createCourse,
  createPdfDocument,
  createResearchPaper,
  deleteCourse,
  deletePdfDocumment,
  deleteResearchPaper,
  deleteSixdHandbook,
  get6DResearchPapers,
  getAllArchivedResearchPapersByOrganisation,
  getAllPdfDocumentsByOrganisation,
  getAllResearchPapersByOrganisation,
  getAllSixdArchiveByOrganisation,
  getArchivedCourses,
  getArchivedWebinars,
  getPdfDocuments,
  getSixdArchive,
  deleteWebinar,
  getArchived6DPdfDocuments,
  getArchived6DResearchPapers,
} from "../../apiFunction/ApiFunctions";
// import localVideo from "../../videos/.mp4"; // Update the path to your local video

const SupportingMaterialsPage = () => {
  const navigate = useNavigate();
  const [id, setId] = useState(null);
  const env = process.env.REACT_APP_ENV;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showCourses, setShowCourses] = useState(false);
  const [showWebinars, setShowWebinars] = useState(false);
  const [showPapers, setShowPapers] = useState(false);
  const [showDocuments, setShowDocuments] = useState(false);
  const [archivedCourses, setArchivedCourses] = useState(null);
  const [archivedWebinars, setArchivedWebinars] = useState(null);
  const [archivePaper, setArchivePaper] = useState(null);
  const [archivedPapers, setArchivedPapers] = useState(null);
  const [archiveDocument, setArchiveDocument] = useState(null);
  const [archiveWebinar, setArchiveWebinar] = useState(null);
  const [archivedDocuments, setArchivedDocuments] = useState(null);
  const [isArchivedCoursesLoading, setIsArchivedCoursesLoading] =
    useState(false);
  const [isArchivedWebinarsLoading, setIsArchivedWebinarsLoading] =
    useState(false);
  const [isArchivedPapersLoading, setIsArchivedPapersLoading] = useState(false);
  const [isArchivedDocumentsLoading, setIsArchivedDocumentsLoading] =
    useState(false);
  const [researchPapers, setResearchPapers] = useState([]);
  const [pdfDocuments, setPdfDocuments] = useState([]);
  const [isResearchPapersLoading, setIsResearchPapersLoading] = useState(true);
  const [isPdfLoading, setIsPdfLoading] = useState(true);
  const [material, setMaterial] = useState("");
  const user = JSON.parse(sessionStorage.getItem("userdetails")) || {};

  const fetchArchivedCourses = () => {
    setShowWebinars(false);
    setShowPapers(false);
    setShowDocuments(false);
    if (archivedCourses) {
      setShowCourses(!showCourses);
    } else {
      setIsArchivedCoursesLoading(true);
      getArchivedCourses()
        .then((data) => {
          console.log("archived courses", data);
          setIsArchivedCoursesLoading(false);
          setShowCourses(true);
          setArchivedCourses(data);
        })
        .catch((error) => {
          console.error("Error fetching hand books", error);
        });
    }
  };

  const fetchArchivedWebinars = () => {
    setShowCourses(false);
    setShowPapers(false);
    setShowDocuments(false);
    if (archivedWebinars) {
      setShowWebinars(!showWebinars);
    } else {
      setIsArchivedWebinarsLoading(true);
      getArchivedWebinars()
        .then((data) => {
          console.log("archived courses", data);
          setIsArchivedWebinarsLoading(false);
          setShowWebinars(true);
          setArchivedWebinars(data);
        })
        .catch((error) => {
          console.error("Error fetching hand books", error);
        });
    }
  };

  const fetchArchivedResearchPapers = () => {
    setShowCourses(false);
    setShowWebinars(false);
    setShowDocuments(false);
    if (archivedPapers) {
      setShowPapers(!showPapers);
    } else {
      setIsArchivedPapersLoading(true);
      getArchived6DResearchPapers()
        .then((data) => {
          console.log("archived courses", data);
          setIsArchivedPapersLoading(false);
          setShowPapers(true);
          setArchivedPapers(data);
        })
        .catch((error) => {
          console.error("Error fetching hand books", error);
        });
    }
  };

  const fetchArchivedPdfDocuments = () => {
    setShowCourses(false);
    setShowWebinars(false);
    setShowPapers(false);
    if (archivedDocuments) {
      setShowDocuments(!showDocuments);
    } else {
      setIsArchivedDocumentsLoading(true);
      getArchived6DPdfDocuments()
        .then((data) => {
          console.log("archived courses", data);
          setIsArchivedDocumentsLoading(false);
          setShowDocuments(true);
          setArchivedDocuments(data);
        })
        .catch((error) => {
          console.error("Error fetching documents", error);
        });
    }
  };

  useEffect(() => {
    get6DResearchPapers()
      .then((data) => {
        console.log("research papers", data);
        setIsResearchPapersLoading(false);
        setResearchPapers(data);
      })
      .catch((error) => {
        console.error("Error fetching papers", error);
      });

    getPdfDocuments()
      .then((data) => {
        console.log("pdf documents", data);
        setIsPdfLoading(false);
        setPdfDocuments(data);
      })
      .catch((error) => {
        console.error("Error fetching pdf documents", error);
      });
  }, []);

  // const openModal = () => {
  //   setIsModalOpen(true);
  // };

  // const closeModal = () => {
  //   setIsModalOpen(false);
  // };

  const handleResearchPaper = async (paper) => {
    console.log(paper);
    const url = `https://66-dementia.b-cdn.net/${env}/researchPapers/contents/${
      paper.id
    }.pdf?${new Date().getTime()}`;
    console.log("url", url);
    window.open(url);
    // window.open("https://dih-test.b-cdn.net/6D%20Dementia%20Training/Please%20C%20Me.pdf");

    // const response = await fetch(url);
    // console.log(response);
  };

  const handlePdfDownload = (pdf) => {
    // const link = document.createElement("a");
    // link.href =
    //   "https://dih-test.b-cdn.net/6D%20Dementia%20Training/Please%20C%20Me.pdf";

    // link.download = "file.pdf"; // This will be the file name
    // document.body.appendChild(link);
    // link.click();
    // document.body.removeChild(link);

    console.log(pdf);
    const url = `https://66-dementia.b-cdn.net/${env}/pdfDocuments/contents/${
      pdf.id
    }.pdf?${new Date().getTime()}`;
    console.log("url", url);
    window.open(url);
  };

  // Function to open the modal
  function openModal(id, material, obj) {
    if (id) {
      console.log("modal open");
      setId(id);
      setIsModalOpen(true);
      setMaterial(material);
    } else {
      console.log("modal open");
      if (material == "researchPaper") {
        setArchivePaper(obj);
      } else if (material == "pdfDocument") {
        setArchiveDocument(obj);
      } else {
        setArchiveWebinar(obj);
      }
      setIsModalOpen(true);
      setMaterial(material);
    }
  }

  // Function to close the modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  // Function to handle the delete confirmation
  function confirmDelete() {
    console.log("Delete confirmed");
    if (material == "researchPaper") {
      deleteResearchPaper(id).then(() => {
        window.location.reload();
      });
    } else if (material == "archiveCourse") {
      deleteCourse(id).then(() => {
        // window.location.reload();
      });
    } else if (material == "pdfDocument") {
      deletePdfDocumment(id).then(() => {
        window.location.reload();
      });
    } else if (material == "webinar") {
      deleteWebinar(id).then(() => {
        window.location.reload();
      });
    }
    setIsModalOpen(false);
  }

  const assessments = [
    { title: "Care Staff Training Assessment 1", button: "Start Assessment" },
    {
      title: "Dementia Team Training Assessment 2",
      button: "Start Assessment",
    },
  ];

  function archive(material) {
    setIsModalOpen(false);
    console.log(material);
    const item = material == "researchPaper" ? archivePaper : archiveDocument;

    // setLoading(true);
    const date = new Date();

    const formData = {
      id: item.id,
      title: item.title,
      description: item.description,
      authors: item.authors,
      publication_date: item.publication_date,
      image: "",
      archive_date: date.toISOString().split("T")[0],
      content: "",
    };

    if (material == "researchPaper") {
      createResearchPaper(formData).then(() => {
        // Update the state to remove the archived course
        setResearchPapers((prevPapers) =>
          prevPapers.filter((c) => c.id !== item.id)
        );
      });
    } else {
      createPdfDocument(formData).then(() => {
        // Update the state to remove the archived course
        setPdfDocuments((prevPdfs) => prevPdfs.filter((c) => c.id !== item.id));
      });
    }

    // setLoading(false);
    navigate("/supporting-materials");
  }

  function unArchive(item, material) {
    // setLoading(true);

    const formData = {
      id: item.id,
      name: item.name,
      description: item.description,
      image: "",
      archive_date: null,
      total_ratings: item.total_ratings,
      average_rating: item.average_rating,
      authors: item.authors,
      title: item.title,
      publication_date: item.publication_date,
      content: "",
    };

    if (material == "course") {
      createCourse(formData).then(() => {
        // Update the state to remove the archived course
        setArchivedCourses((prevCourses) =>
          prevCourses.filter((c) => c.id !== item.id)
        );
      });
    } else if (material == "researchPaper") {
      createResearchPaper(formData).then(() => {
        // Update the state to remove the archived course
        setArchivedPapers((prevPapers) =>
          prevPapers.filter((c) => c.id !== item.id)
        );
      });
    } else if (material == "pdfDocument") {
      createPdfDocument(formData).then(() => {
        // Update the state to remove the archived course
        setArchivedDocuments((prevDocument) =>
          prevDocument.filter((c) => c.id !== item.id)
        );
        // setPdfDocuments(...pdfDocuments, item);
      });
    }
    // setLoading(false);
    setIsModalOpen(false);
    navigate("/supporting-materials");
  }

  // const pdfDocuments = [
  //   { title: "Training Introduction", button: "Download" },
  //   { title: "Training Objectives", button: "Download" },
  // ];

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col">
      <HeaderComponent />
      <main className="w-full p-6 flex flex-col items-center mt-5">
        <div className="w-full max-w-8xl rounded-lg px-8 space-y-6 ml-4">
          {user.roles[0] === "Administrator" && (
            <div className="flex justify-end">
              <button
                className="bg-yellow-500 text-white py-2 px-8 rounded-lg"
                onClick={() => navigate("/upload-supporting-materials")}
              >
                Upload Supporting Material
              </button>
            </div>
          )}
          <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-6">
            {/* Modules */}

            <div>
              <div className="flex justify-between mb-4">
                <h2 className="text-2xl font-semibold text-customBlue">
                  Research Papers
                </h2>
              </div>

              {isResearchPapersLoading && (
                <div role="status" className="ml-[50%]">
                  <svg
                    aria-hidden="true"
                    className="h-8 w-8 animate-spin fill-blue-600 text-gray-200 dark:text-gray-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                  <span className="sr-only">Loading...</span>
                </div>
              )}
              {researchPapers &&
                researchPapers.map((paper, index) => (
                  <div
                    key={paper.id}
                    className="bg-white rounded-lg shadow-lg p-4 mb-4"
                  >
                    <div
                      className="cursor-pointer"
                      onClick={() => handleResearchPaper(paper)}
                    >
                      <img
                        src={`https://66-dementia.b-cdn.net/${env}/researchPapers/images/${
                          paper.id
                        }?${new Date().getTime()}`}
                        alt={paper.name}
                        className="w-full h-36 object-cover rounded-lg mb-4"
                      />
                      <h3 className="flex justify-center text-xl font-semibold mb-2">
                        {paper.title}
                      </h3>
                      <p className="text-gray-700">Author: {paper.authors}</p>
                      <p className="text-gray-700 mb-4">
                        Publication Date: {paper.publication_date}
                      </p>
                    </div>
                    <div className="space-x-4 mt-2 flex justify-center">
                      <button
                        className="bg-yellow-500 text-white py-2 px-4 rounded-lg w-full"
                        onClick={() =>
                          navigate("/create-research-paper", {
                            state: {
                              id: paper.id,
                              title: paper.title,
                              authors: paper.authors,
                              publication_date: paper.publication_date,
                              image: `https://66-dementia.b-cdn.net/${env}/researchPapers/images/${
                                paper.id
                              }?${new Date().getTime()}`,
                              content: `https://66-dementia.b-cdn.net/${env}/researchPapers/contents/${
                                paper.id
                              }.pdf?${new Date().getTime()}`,
                            },
                          })
                        }
                      >
                        Update
                      </button>
                      <button
                        className="bg-blue-500 text-white py-2 px-4 rounded-lg"
                        onClick={() => openModal(null, "researchPaper", paper)}
                        // onClick={() => archive(course)}
                      >
                        Archive
                      </button>
                      <button
                        className="bg-red-500 text-white py-2 px-4 rounded-lg w-full"
                        onClick={() => openModal(paper.id, "researchPaper")}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                ))}
            </div>

            {/* <div>
              <h2 className="text-2xl font-semibold text-customBlue mb-4">
                Assessments
              </h2>
              {assessments.map((assessment, index) => (
                <div
                  key={index}
                  className="bg-white rounded-lg shadow-lg p-4 mb-4"
                >
                  <p className="mb-2">{assessment.title}</p>
                  <button className="bg-customBlue text-white py-2 px-4 rounded-lg w-full">
                    {assessment.button}
                  </button>
                </div>
              ))}
            </div> */}
            <div>
              <div className="flex justify-between mb-4">
                <h2 className="text-2xl font-semibold text-customBlue">
                  Documents
                </h2>
              </div>
              {isPdfLoading && (
                <div role="status" className="ml-[50%]">
                  <svg
                    aria-hidden="true"
                    className="h-8 w-8 animate-spin fill-blue-600 text-gray-200 dark:text-gray-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                  <span className="sr-only">Loading...</span>
                </div>
              )}
              {pdfDocuments &&
                pdfDocuments.map((pdf, index) => (
                  <div
                    key={pdf.id}
                    className="bg-white rounded-lg shadow-lg p-4 mb-4"
                  >
                    <div
                      className="cursor-pointer"
                      onClick={() => handlePdfDownload(pdf)}
                    >
                      <img
                        src={`https://66-dementia.b-cdn.net/${env}/pdfDocuments/images/${
                          pdf.id
                        }?${new Date().getTime()}`}
                        alt={pdf.name}
                        className="w-full h-36 object-cover rounded-lg mb-4"
                      />
                      <h4 className="flex justify-center text-xl font-semibold mb-2">
                        {pdf.title}
                      </h4>
                    </div>
                    {/* <button
                      className="bg-customBlue text-white py-2 px-4 rounded-lg w-full"
                      onClick={() => handlePdfDownload(pdf)}
                    >
                      View
                    </button> */}
                    <div className="space-x-4 mt-2 flex justify-center">
                      <button
                        className="bg-yellow-500 text-white py-2 px-4 rounded-lg w-full"
                        onClick={() =>
                          navigate("/create-pdf-document", {
                            state: {
                              id: pdf.id,
                              title: pdf.title,
                              description: pdf.description,
                              image: `https://66-dementia.b-cdn.net/${env}/pdfDocuments/images/${
                                pdf.id
                              }?${new Date().getTime()}`,
                              content: `https://66-dementia.b-cdn.net/${env}/pdfDocuments/contents/${
                                pdf.id
                              }.pdf?${new Date().getTime()}`,
                            },
                          })
                        }
                      >
                        Update
                      </button>
                      <button
                        className="bg-blue-500 text-white py-2 px-4 rounded-lg"
                        onClick={() => openModal(null, "pdfDocument", pdf)}
                        // onClick={() => archive(course)}
                      >
                        Archive
                      </button>
                      <button
                        className="bg-red-500 text-white py-2 px-4 rounded-lg w-full"
                        onClick={() => openModal(pdf.id, "pdfDocument")}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                ))}
            </div>

            <div>
              <h2 className="text-2xl font-semibold text-customBlue mb-4">
                Archive
              </h2>

              <button
                className="bg-customBlue text-white py-2 px-4 rounded-lg w-full mb-2"
                onClick={fetchArchivedCourses}
              >
                {isArchivedCoursesLoading && (
                  <div role="status" className="ml-[50%]">
                    <svg
                      aria-hidden="true"
                      className="h-8 w-8 animate-spin fill-blue-600 text-gray-200 dark:text-gray-600"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                    <span className="sr-only">Loading...</span>
                  </div>
                )}
                {!isArchivedCoursesLoading && "Courses"}
              </button>
              {showCourses &&
                archivedCourses &&
                (archivedCourses.length > 0 ? (
                  archivedCourses.map((archive, index) => (
                    <div
                      className="bg-white rounded-lg shadow-lg p-4 mb-4"
                      key={archive.id}
                    >
                      <img
                        src={`https://66-dementia.b-cdn.net/${env}/courses/images/${
                          archive.id
                        }?${new Date().getTime()}`}
                        alt={archive.name}
                        className="w-full h-36 object-cover rounded-lg mb-4"
                      />
                      <h3 className="flex justify-center text-xl font-semibold mb-2">
                        {archive.name}
                      </h3>
                      <p className="text-gray-700">
                        <b>Description:</b> {archive.description}
                      </p>
                      <p className="text-gray-700 mb-4">
                        <b>Archive Date:</b> {moment(archive.archive_date).format("DD-MM-YYYY")}
                      </p>
                      <div className="space-x-4 mt-2 flex justify-center">
                        <button
                          className="bg-yellow-500 text-white py-2 px-4 rounded-lg w-full"
                          onClick={() => unArchive(archive, "course")}
                        >
                          Unarchive
                        </button>
                        <button
                          className="bg-red-500 text-white py-2 px-4 rounded-lg w-full"
                          onClick={() => openModal(archive.id, "archiveCourse")}
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="bg-white rounded-lg shadow-lg p-4 mb-4">
                    <p>No Archive Courses!</p>
                  </div>
                ))}
              <button
                className="bg-customBlue text-white py-2 px-4 rounded-lg w-full mb-2"
                onClick={fetchArchivedWebinars}
              >
                {isArchivedWebinarsLoading && (
                  <div role="status" className="ml-[50%]">
                    <svg
                      aria-hidden="true"
                      className="h-8 w-8 animate-spin fill-blue-600 text-gray-200 dark:text-gray-600"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                    <span className="sr-only">Loading...</span>
                  </div>
                )}
                {!isArchivedWebinarsLoading && "Webinars"}
              </button>
              {showWebinars &&
                archivedWebinars &&
                (archivedWebinars.length > 0 ? (
                  archivedWebinars.map((archive, index) => (
                    <div
                      className="bg-white rounded-lg shadow-lg p-4 mb-4"
                      key={archive.id}
                    >
                      <img
                        src={`https://66-dementia.b-cdn.net/${env}/webinars/images/${
                          archive.id
                        }?${new Date().getTime()}`}
                        alt={archive.title}
                        className="w-full h-36 object-cover rounded-lg mb-4"
                      />
                      <h3 className="flex justify-center text-xl font-semibold mb-2">
                        {archive.title}
                      </h3>
                      <p className="text-gray-700">
                        <b>Description:</b> {archive.description}
                      </p>
                      <p className="text-gray-700 mb-4">
                        <b>Archive Date:</b> {archive.archive_date}
                      </p>
                      <div className="space-x-4 mt-2 flex justify-center">
                        <button
                          className={`${archive.webinar_video_bunny_id ? "bg-gray-500 cursor-not-allowed" : "bg-yellow-500"} text-white py-2 px-4 rounded-lg w-full`}
                          onClick={() =>
                            navigate("/upload-webinar-video", {
                              state: {
                                webinar_id: archive.id,
                                webinar_title: archive.title,
                              },
                            })
                          }
                          disabled={archive.webinar_video_bunny_id}
                          title={archive.webinar_video_bunny_id ? "Recorded video is already attached." : ""}
                        >
                          Attach Video
                        </button>
                        <button
                          className={`${
                            archive.webinar_video_bunny_id
                              ? "bg-green-500"
                              : "bg-red-500 cursor-not-allowed"
                          } text-white py-2 px-4 rounded-lg w-full`}
                          onClick={() =>
                            navigate("/mediaplayer", {
                              state: {
                                type: "webinar",
                                title: archive.title,
                                subTitle: archive.description,
                                presenter: archive.presenter,
                                videoId: archive.webinar_video_bunny_id,
                              },
                            })
                          }
                          disabled={!archive.webinar_video_bunny_id}
                          title={!archive.webinar_video_bunny_id ? "Attach a video to unlock this play button." : ""}
                        >
                          Recorded Video
                        </button>
                        <button
                          className="bg-red-500 text-white py-2 px-4 rounded-lg w-full"
                          onClick={() => openModal(archive.id, "webinar")}
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="bg-white rounded-lg shadow-lg p-4 mb-4">
                    <p>No Archive Webinars!</p>
                  </div>
                ))}
              <button
                className="bg-customBlue text-white py-2 px-4 rounded-lg w-full mb-2"
                onClick={fetchArchivedResearchPapers}
              >
                {isArchivedPapersLoading && (
                  <div role="status" className="ml-[50%]">
                    <svg
                      aria-hidden="true"
                      className="h-8 w-8 animate-spin fill-blue-600 text-gray-200 dark:text-gray-600"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                    <span className="sr-only">Loading...</span>
                  </div>
                )}
                {!isArchivedPapersLoading && "Research Papers"}
              </button>
              {showPapers &&
                archivedPapers &&
                (archivedPapers.length > 0 ? (
                  archivedPapers.map((archive, index) => (
                    <div
                      className="bg-white rounded-lg shadow-lg p-4 mb-4"
                      key={archive.id}
                    >
                      <img
                        src={`https://66-dementia.b-cdn.net/${env}/researchPapers/images/${
                          archive.id
                        }?${new Date().getTime()}`}
                        alt={archive.title}
                        className="w-full h-36 object-cover rounded-lg mb-4"
                      />
                      <h3 className="flex justify-center text-xl font-semibold mb-2">
                        {archive.title}
                      </h3>
                      <p className="text-gray-700">
                        <b>Author:</b> {archive.authors}
                      </p>
                      <p className="text-gray-700 mb-4">
                        <b>Archive Date:</b> {archive.archive_date}
                      </p>
                      <div className="space-x-4 mt-2 flex justify-center">
                        <button
                          className="bg-yellow-500 text-white py-2 px-4 rounded-lg w-full"
                          onClick={() => unArchive(archive, "researchPaper")}
                        >
                          Unarchive
                        </button>
                        {/* <button
                          className="bg-red-500 text-white py-2 px-4 rounded-lg w-full"
                          onClick={() => openModal(archive.id, "archiveCourse")}
                        >
                          Delete
                        </button> */}
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="bg-white rounded-lg shadow-lg p-4 mb-4">
                    <p>No Archive Research Papers!</p>
                  </div>
                ))}
              <button
                className="bg-customBlue text-white py-2 px-4 rounded-lg w-full mb-2"
                onClick={fetchArchivedPdfDocuments}
              >
                {isArchivedDocumentsLoading && (
                  <div role="status" className="ml-[50%]">
                    <svg
                      aria-hidden="true"
                      className="h-8 w-8 animate-spin fill-blue-600 text-gray-200 dark:text-gray-600"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                    <span className="sr-only">Loading...</span>
                  </div>
                )}
                {!isArchivedDocumentsLoading && "Documents"}
              </button>
              {showDocuments &&
                archivedDocuments &&
                (archivedDocuments.length > 0 ? (
                  archivedDocuments.map((archive, index) => (
                    <div
                      className="bg-white rounded-lg shadow-lg p-4 mb-4"
                      key={archive.id}
                    >
                      <img
                        src={`https://66-dementia.b-cdn.net/${env}/pdfDocuments/images/${
                          archive.id
                        }?${new Date().getTime()}`}
                        alt={archive.title}
                        className="w-full h-36 object-cover rounded-lg mb-4"
                      />
                      <h3 className="flex justify-center text-xl font-semibold mb-2">
                        {archive.title}
                      </h3>
                      <p className="text-gray-700">
                        <b>Description:</b> {archive.description}
                      </p>
                      <p className="text-gray-700 mb-4">
                        <b>Archive Date:</b> {archive.archive_date}
                      </p>
                      <div className="space-x-4 mt-2 flex justify-center">
                        <button
                          className="bg-yellow-500 text-white py-2 px-4 rounded-lg w-full"
                          onClick={() => unArchive(archive, "pdfDocument")}
                        >
                          Unarchive
                        </button>
                        {/* <button
                          className="bg-red-500 text-white py-2 px-4 rounded-lg w-full"
                          onClick={() => openModal(archive.id, "archiveCourse")}
                        >
                          Delete
                        </button> */}
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="bg-white rounded-lg shadow-lg p-4 mb-4">
                    <p>No Archive Documents!</p>
                  </div>
                ))}
            </div>

            {/* Modal */}
            {isModalOpen && (
              <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full">
                  <h2 className="text-xl font-bold mb-4">
                    Confirm {id ? "Delete" : "Archive"}
                  </h2>
                  <p className="text-gray-600 mb-6">
                    {id
                      ? "Are you sure you want to delete this item?"
                      : "Are you sure you want to Archive this item?"}
                  </p>
                  <div className="flex justify-end space-x-4">
                    <button
                      className="bg-gray-500 text-white py-2 px-4 rounded-lg hover:bg-gray-600"
                      onClick={closeModal}
                    >
                      Cancel
                    </button>
                    <button
                      className="bg-red-600 text-white py-2 px-4 rounded-lg hover:bg-red-700"
                      onClick={() => {
                        id ? confirmDelete() : archive(material);
                      }}
                    >
                      Confirm
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
          <FooterComponent path={-1} />
        </div>
      </main>
      {/* <VideoModal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        videoUrl={localVideo}
      /> */}
    </div>
  );
};

export default SupportingMaterialsPage;
