import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import {
  createCheckoutSession,
  getProductByOrganisationType,
  getProducts,
  getSubscriptionsByOrganisation,
} from "../../apiFunction/ApiFunctions";
import FooterComponent from "../admin/shared/FooterComponent";
import HeaderComponent from "../admin/shared/HeaderComponent";

const Subscriptions = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { state } = location;
  const env = process.env.REACT_APP_ENV;
  const [subscription, setSubscription] = useState(null);
  const [product, setProduct] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubscribing, setIsSubscribing] = useState(false);
  const userOrganisationDetails = JSON.parse(
    sessionStorage.getItem("organisationDetails")
  )[0];

  useEffect(() => {
    getSubscriptionsByOrganisation()
      .then(async (data) => {
        if (data.ok) {
          const responseData = await data.json();
          console.log("subscriptions", responseData);
          if (responseData) {
            setIsLoading(false);
            setSubscription(responseData);
          } else {
            getProductByOrganisationType().then(async (response) => {
              if (response.ok) {
                const productData = await response.json();
                setIsLoading(false);
                console.log("product", productData);
                setProduct(productData);
              } else {
                setIsLoading(false);
                console.error("Failed to fetch products:", response.statusText);
                setError("Failed to fetch Product");
              }
            });
          }
        } else {
          setIsLoading(false);
          setError("Failed to fetch Subscription Details");
          console.error("Failed to fetch subscription:", data.statusText);
        }
      })
      .catch((error) => {
        console.error("Error fetching subscriptions:", error);
      });
  }, []);

  const subscribe = (product) => {
    setError(null);
    setIsSubscribing(true);
    const item = {
      line_items: [
        {
          name: product.name,
          unit_price: product.unit_amount / 100,
        },
      ],
    };
    createCheckoutSession(item)
      .then(async (response) => {
        if (response.ok) {
          const url = await response.json();
          setIsSubscribing(false);
          console.log("responsedata", url);
          window.open(url);
          navigate("/managerhomescreen");
        } else {
          setIsSubscribing(false);
          setError("Error creating checkout session");
          console.error("Failed to fetch:", response.statusText);
        }
      })
      .catch((error) => {
        setIsSubscribing(false);
        setError(error);
        console.error("Error creating checkout session:", error);
      });
  };

  const renewSubscription = () => {
    setError(null);
    setIsSubscribing(true);
    getProductByOrganisationType().then(async (response) => {
      if (response.ok) {
        const productData = await response.json();
        console.log("product", productData);

        subscribe(productData);
      } else {
        setIsSubscribing(false);
        console.error("Failed to fetch products:", response.statusText);
        setError("Failed to fetch Product");
      }
    });
  };

  return (
    <div className="min-h-screen p-4">
      <HeaderComponent />
      <div className="container mx-auto">
        <div className="mb-8 ml-[]">
          <h2 className="text-2xl font-medium text-customBlue">
            Subscription for: {userOrganisationDetails.name}
          </h2>
        </div>
        <div className="space-y-12 mb-8">
          {isLoading && (
            <div role="status" className="ml-[50%]">
              <svg
                aria-hidden="true"
                className="h-8 w-8 animate-spin fill-blue-600 text-gray-200 dark:text-gray-600"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
              <span className="sr-only">Loading...</span>
            </div>
          )}
          {product && (
            <>
              <div className="bg-white rounded-lg shadow-lg p-4 mb-4 flex justify-between">
                <p>{product.name}</p>
                <p>Price: £ {product.unit_amount / 100}</p>

                <button
                  className={`text-white w-32 py-2 rounded-lg bg-green-500`}
                  onClick={() => {
                    if (!isSubscribing) {
                      subscribe(product);
                    }
                  }}
                >
                  {isSubscribing && (
                    <div role="status" className="ml-[40%]">
                      <svg
                        aria-hidden="true"
                        className="h-6 w-6 animate-spin fill-white text-gray-200 dark:text-gray-600"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentFill"
                        />
                      </svg>
                      <span className="sr-only">Loading...</span>
                    </div>
                  )}
                  {!isSubscribing && "Subscribe"}
                </button>
              </div>

              {error && (
                <div
                  className="bg-red-100 border border-red-400 text-red-700 px-2 py-3 rounded
                  w-1/3 ml-[33.33%] text-center"
                  role="alert"
                >
                  <span className="block  sm:inline">Error : {error}</span>
                </div>
              )}
            </>
          )}
        </div>
      </div>

      {/* Subscription Card */}
      {subscription && (
        <div className="flex-grow flex items-center justify-center p-6">
          <div className="bg-white shadow-lg rounded-lg p-8 w-full max-w-2xl text-center">
            <img
              src={`https://66-dementia.b-cdn.net/${env}/organisations/images/${
                userOrganisationDetails.id
              }?${new Date().getTime()}`}
              alt={userOrganisationDetails.name}
              className="w-32 h-32 mx-auto rounded-full shadow-md mb-4"
            />

            <h2 className="text-2xl font-medium text-customBlue mb-6">
              {userOrganisationDetails.name}
            </h2>

            <div className="text-left mb-6">
              <h3 className="text-lg font-semibold mb-4">
                Subscription Details
              </h3>
              <p className="text-gray-700 mb-1">
                Users: {subscription.total_users}
              </p>
              <p className="text-gray-700 mb-1">
                Training Completion:{" "}
                {subscription.training_completion_percentage}%
              </p>
              <p className="text-gray-700 mb-1">
                Type: {userOrganisationDetails.type}
              </p>
              {/* <p className="text-gray-700 mb-1">Start Date: Not Available</p> */}
              <p className="text-gray-700">
                Expiry Date:{" "}
                {subscription
                  ? new Date(subscription.expiry_date).toLocaleDateString()
                  : ""}
              </p>
            </div>

            <div className="flex  justify-center gap-4">
              <button
                className="px-6 py-2 bg-blue-900 text-white rounded-md hover:bg-blue-700"
                onClick={() => {
                  if (!isSubscribing) {
                    renewSubscription();
                  }
                }}
              >
                {isSubscribing && (
                  <div role="status" className="ml-[25%]">
                    <svg
                      aria-hidden="true"
                      className="h-6 w-6 animate-spin fill-white text-gray-200 dark:text-gray-600"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                    <span className="sr-only">Loading...</span>
                  </div>
                )}
                {!isSubscribing && "Renew Subscription"}
              </button>
              {/* <button className="px-6 py-2 bg-red-500 text-white rounded-md hover:bg-red-400">
                Cancel Subscription
              </button> */}
            </div>
            {error && (
              <div
                className="bg-red-100 border border-red-400 text-red-700 px-2 py-3 rounded mt-2
                  w-1/2 ml-[25%] text-center"
                role="alert"
              >
                <span className="block  sm:inline">Error : {error}</span>
              </div>
            )}
          </div>
        </div>
      )}
      <FooterComponent path={-1} state={state} />
    </div>
  );
};

export default Subscriptions;
