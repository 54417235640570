import React, { useEffect, useState } from "react";
import Header from "./Header";
// import module1 from "../../images/coursedetails/1.png";
// import module2 from "../../images/coursedetails/2.png";
// import module3 from "../../images/coursedetails/3.png";
// import module4 from "../../images/coursedetails/4.png";
import { useLocation, useNavigate } from "react-router-dom";
import {
  // getCompletedModulesByUserCourse,
  getLessonsByModuleAndUser,
  // getModulesByCourse,
  getModulesByUserCourse,
} from "../../apiFunction/ApiFunctions";
import HeaderComponent from "../admin/shared/HeaderComponent";

const TrainingModules = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const env = process.env.REACT_APP_ENV;
  const [isLoading, setIsLoading] = useState(true);
  const [isLessonsLoading, setIsLessonsLoading] = useState(false);
  const [modules, setModules] = useState(null);
  const [focusedModule, setFocusedModule] = useState(null);
  const [focusedModuleNumber, setFocusedModuleNumber] = useState(null);
  const [focusedModuleLessons, setFocusedModuleLessons] = useState(null);
  // const [completedModules, setCompletedModules] = useState(null);
  const user = JSON.parse(sessionStorage.getItem("userdetails"));
  console.log(state);

  function changeFocusedModule(module) {
    // Add the current focused module back to the modules array
    const updatedModules = [...modules, focusedModule];

    // Remove the newly selected module from the updated modules array
    const remainingModules = updatedModules.filter((m) => m.id !== module.id);

    // Update states
    setModules(remainingModules.sort((a, b) => a.id - b.id));
    setFocusedModule(module);
  }

  useEffect(() => {
    getModulesByUserCourse(state.userCourseId)
      .then((data) => {
        console.log(
          "modules",
          data.sort((a, b) => a.number - b.number)
        );
        const sortedModules = data.sort((a, b) => a.number - b.number);

        // const focused = sortedModules[0];
        // const remainingModules = sortedModules.filter(
        //   (module) => module.id !== focused.id
        // );
        setIsLoading(false);
        setModules(sortedModules);
        setFocusedModule(sortedModules[0]);
        setFocusedModuleNumber(1);
        getFocusedModuleLessons(sortedModules[0].id);
      })
      .catch((error) => {
        console.error("Error fetching modules", error);
      });

    // getCompletedModulesByUserCourse(state.userCourseId)
    //   .then((data) => {
    //     console.log("completed modules", data);
    //     setCompletedModules(data.map((module) => module.module_id));
    //   })
    //   .catch((error) => {
    //     console.error("Error fetching modules", error);
    //   });
  }, [state.userCourseId]);

  const getFocusedModuleLessons = (moduleId) => {
    setIsLessonsLoading(true);
    getLessonsByModuleAndUser(moduleId)
      .then((data) => {
        console.log("lessons", data);
        setFocusedModuleLessons(data.sort((a, b) => a.id - b.id));
        setIsLessonsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching lessons", error);
      });
  };

  return (
    <div className="min-h-screen bg-white ">
        <HeaderComponent />
      <div className="container mx-auto px-4">
        <h2 className="text-2xl font-bold mb-2">{state.courseName}</h2>
        {isLoading && (
          <div role="status" className="ml-[50%]">
            <svg
              aria-hidden="true"
              className="h-8 w-8 animate-spin fill-blue-600 text-gray-200 dark:text-gray-600"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
            <span className="sr-only">Loading...</span>
          </div>
        )}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          <div>
            {modules &&
              modules
                // .filter((module) => module.id !== focusedModule.id)
                .map((module, index) => (
                  <div
                    key={module.id}
                    className={`bg-white p-4 rounded-lg shadow-lg mb-4 ${
                      module.id === focusedModule.id ? "hidden" : ""
                    }`}
                    onClick={() => {
                      // changeFocusedModule(module);
                      setFocusedModule(module);
                      setFocusedModuleNumber(module.number);
                      getFocusedModuleLessons(module.id);
                    }}
                  >
                    <div className="flex items-center">
                      <img
                        src={`https://66-dementia.b-cdn.net/${env}/modules/images/${
                          module.id
                        }?${new Date().getTime()}`}
                        alt={module.title}
                        className="w-32 h-20 object-cover rounded-lg mr-4"
                      />
                      <div>
                        <h3 className="text-xl font-bold mb-1">
                          {/* {`Module -`} {module.title} */}
                          {`Module ${module.number} -`} {module.title}
                        </h3>
                        <p className="text-gray-700 mb-2">
                          Completed: {module.completed ? "Yes" : "No"}
                        </p>

                        {/* <button
                          className="bg-blue-900 text-white px-6 py-2 rounded-lg shadow-lg hover:bg-blue-700"
                          onClick={() => {
                            navigate("/lessons", {
                              state: {
                                moduleId: module.id,
                                moduleNumber: index + 2,
                                moduleTitle: module.title,
                                courseName: state.courseName,
                              },
                            });
                          }}
                        >
                          View
                        </button> */}
                      </div>
                    </div>
                  </div>
                ))}
          </div>
          <div>
            {modules && (
              <div className="bg-white p-8 rounded-lg shadow-lg">
                <img
                  src={`https://66-dementia.b-cdn.net/${env}/modules/images/${
                    focusedModule.id
                  }?${new Date().getTime()}`}
                  alt={focusedModule.title}
                  className="mx-auto w-3/4 h-52 object-cover rounded-lg mb-4"
                />
                <h3 className="text-xl font-bold mb-2">
                  Module {focusedModuleNumber} : {focusedModule.title}
                  {/* Module : {focusedModule.title} */}
                </h3>
                <div className=" mb-4">
                  <div className="bg-white py-2  w-[40%] rounded-3xl shadow-lg flex items-center justify-evenly">
                    <img
                      src={`https://66-dementia.b-cdn.net/${env}/users/pictures/${
                        user.id
                      }?${new Date().getTime()}`}
                      alt="Profile"
                      className=" h-10 w-10 rounded-2xl object-cover"
                    />
                    <p className=" text-gray-700">
                      {focusedModule.completed ? "" : "Not"} Completed
                    </p>
                  </div>
                  <p className=" text-gray-700 mt-2 flex items-center">
                    <svg
                      className="w-5 h-5 text-blue-900"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path d="M0 0h24v24H0z" fill="none"></path>
                      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"></path>
                    </svg>
                    {!isLessonsLoading && (
                      <span className="ml-2">
                        {focusedModuleLessons.reduce(
                          (duration, lesson) =>
                            duration + lesson.duration_minutes,
                          0
                        )}{" "}
                        min
                      </span>
                    )}
                  </p>
                  {/* <p className=" text-gray-700 mt-2 flex items-center">
                    <svg
                      className="w-4 h-4 text-blue-900"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M10 15l-5.878 3.09 1.123-6.545L.488 6.909l6.561-.953L10 0l2.951 5.956 6.561.953-4.757 4.636 1.123 6.545z" />
                    </svg>
                    <span className="ml-2"> {focusedModule.total_ratings}/5</span>
                  </p> */}
                  <p className=" text-gray-700 mt-2 flex items-center">
                    <svg className="w-5 h-5 text-[#030303]" viewBox="0 0 24 24">
                      <path fill="none" d="M0 0h24v24H0z"></path>
                      <path d="m20.5 10 .5-2h-4l1-4h-2l-1 4h-4l1-4h-2L9 8H5l-.5 2h4l-1 4h-4L3 16h4l-1 4h2l1-4h4l-1 4h2l1-4h4l.5-2h-4l1-4h4zm-7 4h-4l1-4h4l-1 4z"></path>
                    </svg>
                    {!isLessonsLoading && (
                      <span className="ml-2">
                        {" "}
                        {focusedModuleLessons.length} Topics
                      </span>
                    )}
                  </p>
                </div>
                <ul className="list-disc list-inside text-gray-700 mb-4">
                  {focusedModule.description.split(". ").map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
                <div className="flex justify-center">
                  <button
                    className="bg-blue-900 text-white px-20 py-2 rounded-lg shadow-lg hover:bg-blue-700"
                    onClick={() => {
                      navigate("/lessons", {
                        state: {
                          moduleId: focusedModule.id,
                          moduleNumber: focusedModuleNumber,
                          moduleTitle: focusedModule.title,
                          moduleCompleted: focusedModule.completed,
                          courseName: state.courseName,
                          modules: modules,
                        },
                      });
                    }}
                  >
                    View
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="mt-8 mb-2 flex justify-center">
          <button
            className="bg-blue-900 text-white px-10 py-2 rounded-3xl shadow-lg hover:bg-blue-700"
            onClick={() => {
              navigate(-1);
            }}
          >
            Back
          </button>
        </div>
      </div>
    </div>
  );
};

export default TrainingModules;
