// import React, { useState } from "react";
// import { useLocation, useNavigate } from "react-router-dom";
// import HeaderComponent from "../admin/shared/HeaderComponent";

// const RegisteredWebinars = () => {
//   const navigate = useNavigate();
//   const location = useLocation();
//   const [showAlert, setShowAlert] = useState(false);
//   const { state } = location;
//   console.log(state);

//   return (
//     <div className="min-h-screen flex flex-col items-center bg-white ">
//       <div className="container mx-auto px-4">
//         <HeaderComponent />
//         <h2 className="text-3xl font-bold mb-2 ">Your Registered Webinars</h2>
//         {state.registeredWebinars.length === 0 && (
//           <h2 className="text-3xl mt-4 mb-2 ">No Registered Webinars!</h2>
//         )}
//         <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
//           {state.registeredWebinars.map((webinar, index) => (
//             <div key={webinar.id} className="bg-white p-4 rounded-lg shadow-lg">
//               <div className=" mb-4">
//                 <img
//                   src={`https://66-dementia.b-cdn.net/${env}/webinars/images/${
//                     webinar.id
//                   }?${new Date().getTime()}`}
//                   alt={webinar.title}
//                   className="w-full h-48  object-cover rounded-lg mb-4"
//                 />
//               </div>
//               <h2 className="text-lg  mb-2">
//                 {new Date(webinar.date_time).toLocaleDateString()} -{" "}
//                 {new Date(webinar.date_time).getHours() < 10
//                   ? "0" + new Date(webinar.date_time).getHours()
//                   : new Date(webinar.date_time).getHours()}
//                 :
//                 {new Date(webinar.date_time).getMinutes() < 10
//                   ? "0" + new Date(webinar.date_time).getMinutes()
//                   : new Date(webinar.date_time).getMinutes()}
//                 {/* {new Date(webinar.date_time).getHours()}:
//                 {new Date(webinar.date_time).getMinutes()} */}
//               </h2>
//               <h3 className="text-xl font-bold mb-2">{webinar.title}</h3>
//               <p className="text-gray-600 mb-2">
//                 Presented by: {webinar.presenter}
//               </p>
//               <p className="text-gray-700 mb-4 flex-grow">{webinar.description}</p>

//               <button
//                 className={`bg-blue-900 text-white px-6 py-2 rounded-lg shadow-lg hover:bg-blue-700 mt-8
//                 ${
//                   new Date(webinar.date_time).getTime() <= new Date().getTime()
//                     ? ""
//                     : "cursor-not-allowed"
//                 }
//                 `}
//                 onClick={() => {
//                   if (
//                     new Date(webinar.date_time).getTime() <=
//                     new Date().getTime()
//                   ) {
//                     console.log("clicked");
//                     window.open(webinar.live_url);
//                   } else {
//                     setShowAlert(true);
//                   }
//                 }}
//               >
//                 Join
//               </button>
//             </div>
//           ))}
//         </div>
//         {/* Modal */}

//         {showAlert && (
//           <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
//             <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full">
//               <h2 className="text-xl font-bold mb-4">Alert</h2>
//               <p className="text-gray-600 mb-6">
//                 You cannot join the webinar before its scheduled start time.
//               </p>

//               <div className="flex justify-end space-x-4">
//                 <button
//                   className="bg-red-600 text-white py-2 px-4 rounded-lg hover:bg-red-700"
//                   onClick={() => {
//                     setShowAlert(false);
//                   }}
//                 >
//                   Ok
//                 </button>
//               </div>
//             </div>
//           </div>
//         )}

//         <div className="mt-8 flex justify-center">
//           <button
//             className="bg-blue-900 text-white px-10 py-2 rounded-3xl shadow-lg hover:bg-blue-700"
//             onClick={() => {
//               navigate(-1);
//             }}
//           >
//             Back
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default RegisteredWebinars;

import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import HeaderComponent from "../admin/shared/HeaderComponent";

const RegisteredWebinars = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const env = process.env.REACT_APP_ENV;
  const [showAlert, setShowAlert] = useState(false);
  const { state } = location;

  return (
    <div className="min-h-screen  bg-white">
      <HeaderComponent />
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold mb-2">Your Registered Webinars</h2>
        {state.registeredWebinars.length === 0 && (
          <h2 className="text-3xl mt-4 mb-2">No Registered Webinars!</h2>
        )}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {state.registeredWebinars.map((webinar) => (
            <div
              key={webinar.id}
              className="bg-white p-4 rounded-lg shadow-lg flex flex-col justify-between"
            >
              <div>
                <img
                  src={`https://66-dementia.b-cdn.net/${env}/webinars/images/${
                    webinar.id
                  }?${new Date().getTime()}`}
                  alt={webinar.title}
                  className="w-full h-48 object-cover rounded-lg mb-4"
                />
                <h2 className="text-lg mb-2">
                  {new Date(webinar.date_time).toLocaleDateString()} -{" "}
                  {new Date(webinar.date_time)
                    .getHours()
                    .toString()
                    .padStart(2, "0")}
                  :
                  {new Date(webinar.date_time)
                    .getMinutes()
                    .toString()
                    .padStart(2, "0")}
                </h2>
                <h3 className="text-xl font-bold mb-2">{webinar.title}</h3>
                <p className="text-gray-600 mb-2">
                  Presented by: {webinar.presenter}
                </p>
                <p className="text-gray-700 flex-grow">{webinar.description}</p>
              </div>
              <button
                className={`bg-blue-900 text-white px-6 py-2 rounded-lg shadow-lg hover:bg-blue-700 mt-4 
                  ${
                    new Date(webinar.date_time).getTime() <=
                    new Date().getTime()
                      ? ""
                      : "cursor-not-allowed"
                  }
                `}
                onClick={() => {
                  if (
                    new Date(webinar.date_time).getTime() <=
                    new Date().getTime()
                  ) {
                    window.open(webinar.live_url);
                  } else {
                    setShowAlert(true);
                  }
                }}
              >
                Join
              </button>
            </div>
          ))}
        </div>

        {/* Modal */}
        {showAlert && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full">
              <h2 className="text-xl font-bold mb-4">Alert</h2>
              <p className="text-gray-600 mb-6">
                You cannot join the webinar before its scheduled start time.
              </p>
              <div className="flex justify-end space-x-4">
                <button
                  className="bg-red-600 text-white py-2 px-4 rounded-lg hover:bg-red-700"
                  onClick={() => setShowAlert(false)}
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        )}

        <div className="mt-8 flex justify-center mb-2">
          <button
            className="bg-blue-900 text-white px-10 py-2 rounded-3xl shadow-lg hover:bg-blue-700"
            onClick={() => navigate(-1)}
          >
            Back
          </button>
        </div>
      </div>
    </div>
  );
};

export default RegisteredWebinars;
