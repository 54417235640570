import React, { useEffect, useState } from "react";
import HeaderComponent from "./shared/HeaderComponent";
import { useNavigate } from "react-router-dom";
import {
  createStripeAccountByOrganisationAndUser,
  getCompletedPercentageByOrganisation,
  getLocationsByOrganisation,
  getOrganisationById,
  getSubscriptionsByOrganisation,
  getTotalUsersByOrganisation,
  getUsersByOrganisation,
  userInfo,
} from "../../apiFunction/ApiFunctions";
import { useLocation } from "react-router-dom";
import FooterComponent from "./shared/FooterComponent";

const OrganizationDashboardPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const env = process.env.REACT_APP_ENV;
  const [isEditable, setIsEditable] = useState(false);
  const [organisationName, setOrganisationName] = useState(state.name);
  const [organisationType, setOrganisationType] = useState(state.type);
  const [address, setAddress] = useState(state.address);
  const [phone, setPhone] = useState(state.phone_number);
  const [stripeId, setStripeId] = useState(null);
  const [completedTraining, setCompletedTraining] = useState(null);
  const [administrators, setAdministrators] = useState(null);
  const [users, setUsers] = useState(null);
  const [locations, setLocations] = useState(null);
  const [subscriptions, setSubscriptions] = useState(null);
  const [isAdminLoading, setIsAdminLoading] = useState(true);
  const [isUsersLoading, setIsUserLoading] = useState(true);
  const [isLocationsLoading, setIsLocationsLoading] = useState(true);
  const [isSubscriptionsLoading, setIsSubscriptionsLoading] = useState(true);
  const auth = JSON.parse(sessionStorage.getItem("userdetails"));
  const [activeTab, setActiveTab] = useState("administrators");
  // const userOrganisationDetails = JSON.parse(
  //   sessionStorage.getItem("organisationDetails")
  // )[0];

  useEffect(() => {
    getOrganisationById(state.id)
      .then((data) => {
        // console.log("totalusers", data);
        setStripeId(data.stripe_id ? data.stripe_id : null);
      })
      .catch((error) => {
        console.error("Error fetching organisation:", error);
      });

    // getTotalUsersByOrganisation()
    //   .then((data) => {
    //     console.log("totalusers", data);
    //     setRegisteredStaff(data);
    //   })
    //   .catch((error) => {
    //     console.error("Error fetching total users:", error);
    //   });

    // getCompletedPercentageByOrganisation()
    //   .then((data) => {
    //     console.log("completedpercentage", data);
    //     setCompletedTraining(data);
    //   })
    //   .catch((error) => {
    //     console.error("Error fetching total users:", error);
    //   });

    getLocationsByOrganisation(state.id)
      .then((data) => {
        console.log("locations", data);
        setIsLocationsLoading(false);
        setLocations(data);
      })
      .catch((error) => {
        console.error("Error fetching total Locations:", error);
      });

    getUsersByOrganisation(state.id)
      .then((data) => {
        console.log("users", data);
        const admins = data.filter(
          (user) =>
            user.roles[0] === "OrganisationAdministrator" ||
            user.roles[0] === "Manager"
        );
        const users = data.filter(
          (user) =>
            user.roles[0] !== "OrganisationAdministrator" &&
            user.roles[0] !== "Manager"
        );
        setIsAdminLoading(false);
        setIsUserLoading(false);
        setAdministrators(admins);
        setUsers(users);
        console.log("admins", admins);
        console.log("stripe", stripeId);

        if (admins.length == 1) {
          console.log("hello..Entered")
          createStripeAccountByOrganisationAndUser(
            state.id,
            admins[0].id
          )
            .then((data) => {
              console.log(data);
            })
            .catch((error) => {
              console.error("Error creating stripe id:", error);
            });
        }
      })
      .catch((error) => {
        console.error("Error fetching total users:", error);
      });

    getSubscriptionsByOrganisation(state.id)
      .then(async (data) => {
        const sub = await data.json();
        console.log("subscription", sub);
        setIsSubscriptionsLoading(false);
        setSubscriptions(sub);
      })
      .catch((error) => {
        console.error("Error fetching total users:", error);
      });
  }, []);

  // const locations = [
  //   {
  //     name: "Lisburn Care Home",
  //     image_url: require("../../images/teams/image.png"),
  //   },
  //   {
  //     name: "Lisburn Road Care Home",
  //     image_url: require("../../images/teams/image (1).png"),
  //   },
  //   {
  //     name: "Antrim Care Home",
  //     image_url: require("../../images/teams/image (2).png"),
  //   },
  // ];

  function enableEdit() {
    setIsEditable(true);
  }

  return (
    <div className="min-h-screen bg-white">
      <HeaderComponent />
      <div className="container mx-auto p-6">
        {state && (
          <div className="flex mb-8 justify-between">
            <div className="flex">
              <img
                src={`https://66-dementia.b-cdn.net/${env}/organisations/images/${
                  state.id
                }?${new Date().getTime()}`}
                alt="Loughview Care Home"
                className="w-32 h-32 rounded-full mr-6"
              />
              <div className=" flex flex-col items-center justify-evenly">
                <h2 className="text-3xl text-customBlue ">
                  {organisationName}
                </h2>
              </div>
            </div>
          </div>
        )}

        {/* 6D Admin */}
        {auth.roles[0] === "Administrator" && (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-8 ml-40">
            <div>
              <label className="block mb-2">Organisation Name</label>
              <input
                type="text"
                value={organisationName}
                onChange={(e) => setOrganisationName(e.target.value)}
                className="w-full border p-2 rounded"
                readOnly={!isEditable}
              />
            </div>
            <div>
              <label className="block mb-2">Organisation Type</label>
              <input
                type="text"
                value={organisationType}
                onChange={(e) => setOrganisationType(e.target.value)}
                className="w-full border p-2 rounded"
                readOnly={!isEditable}
              />
            </div>
            <div>
              <label className="block mb-2">Address</label>
              <textarea
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                className="w-full border p-2 rounded"
                rows="2"
                readOnly={!isEditable}
              />
            </div>
            <div>
              <label className="block mb-2">Website</label>
              <a
                href={state.website_url}
                target="_blank"
                rel="noopener noreferrer"
                className="block mb-2 text-blue-600"
              >
                {state.website_url}
              </a>
              <div className="flex justify-center gap-2">
                <button
                  className="bg-gray-600 p-1 text-white rounded-lg w-16"
                  onClick={() =>
                    navigate("/create-organization", {
                      state,
                    })
                  }
                >
                  Edit
                </button>
              </div>
            </div>
            <div>
              <label className="block mb-2">Phone</label>
              <input
                type="text"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                className="w-full border p-2 rounded"
                readOnly={!isEditable}
              />
            </div>
          </div>
        )}

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-8 ml-24">
          <div>
            <h3 className="text-2xl font-semibold mb-4">Manage Users</h3>
            <div className="flex space-x-4 mb-4">
              <button
                className={`p-2 text-lg font-semibold ${
                  activeTab === "administrators"
                    ? "text-blue-600 border-b-2 border-blue-600"
                    : "text-gray-600"
                }`}
                onClick={() => setActiveTab("administrators")}
              >
                Administrators
              </button>
              <button
                className={`p-2 text-lg font-semibold ${
                  activeTab === "users"
                    ? "text-blue-600 border-b-2 border-blue-600"
                    : "text-gray-600"
                }`}
                onClick={() => setActiveTab("users")}
              >
                Users
              </button>
            </div>

            {/* Tab Content */}
            <div className="bg-white rounded-3xl shadow-lg p-4">
              {/* Administrators Section */}
              {activeTab === "administrators" && (
                <>
                  {isAdminLoading && (
                    <div role="status" className="flex justify-center">
                      <svg
                        aria-hidden="true"
                        className="h-8 w-8 animate-spin fill-blue-600 text-gray-200 dark:text-gray-600"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentFill"
                        />
                      </svg>
                      <span className="sr-only">Loading...</span>
                    </div>
                  )}
                  {administrators && administrators.length > 0 ? (
                    administrators.slice(0, 3).map((item) => (
                      <div key={item.id} className="flex items-center mb-4">
                        <img
                          src={`https://66-dementia.b-cdn.net/${env}/users/pictures/${item.id}`}
                          className="w-12 h-12 rounded-full mr-4"
                        />
                        <div>
                          <h4 className="font-semibold">{item.name}</h4>
                          <span className="text-gray-600">{item.roles[0]}</span>
                        </div>
                        {/* <button
                          className="ml-auto bg-gray-100 text-gray-700 p-1 rounded-3xl border border-gray-400 w-32"
                          onClick={() => openModal(item.id)}
                        >
                          Profile
                        </button> */}
                      </div>
                    ))
                  ) : (
                    <p className="text-gray-600">
                      {!isAdminLoading && "No Admins for this Organisation."}
                    </p>
                  )}
                  {administrators && administrators.length > 0 && (
                    <div className="flex justify-center">
                      <button
                        className="bg-white text-gray-700 p-1 rounded-2xl shadow w-32 border border-gray-400"
                        onClick={() =>
                          navigate("/user-list", {
                            state: {
                              users: administrators,
                              organisation_name: state.name,
                            },
                          })
                        }
                      >
                        All Admins
                      </button>
                    </div>
                  )}
                  <div className="flex justify-center mt-4">
                    <button
                      className="bg-green-600 text-white p-1 rounded-2xl shadow w-32 border border-gray-400"
                      onClick={() =>
                        navigate("/create-organization-admin", {
                          state: {
                            locations: locations,
                          },
                        })
                      }
                    >
                      Create
                    </button>
                  </div>
                </>
              )}

              {/* Users Section */}
              {activeTab === "users" && (
                <>
                  {isUsersLoading && (
                    <div role="status" className="flex justify-center mb-4">
                      <svg
                        aria-hidden="true"
                        className="h-8 w-8 animate-spin fill-blue-600 text-gray-200 dark:text-gray-600"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentFill"
                        />
                      </svg>
                      <span className="sr-only">Loading...</span>
                    </div>
                  )}
                  {users && users.length > 0 ? (
                    users.slice(0, 3).map((item) => (
                      <div key={item.id} className="flex items-center mb-4">
                        <img
                          src={`https://66-dementia.b-cdn.net/${env}/users/pictures/${item.id}`}
                          alt="User"
                          className="w-12 h-12 rounded-full mr-4"
                        />
                        <div>
                          <h4 className="font-semibold">{item.name}</h4>
                        </div>
                        {/* <button
                          className="ml-auto bg-gray-100 text-gray-700 p-1 rounded-3xl w-32 border border-gray-400"
                          onClick={() =>
                            navigate("/user-profile", {
                              state: {
                                ...state,
                              },
                            })
                          }
                        >
                          View Profile
                        </button> */}
                      </div>
                    ))
                  ) : (
                    <p className="text-gray-600">
                      {!isUsersLoading && "No Users for this Organisation."}
                    </p>
                  )}
                  {users && users.length > 0 && (
                    <div className="flex justify-center">
                      <button
                        className="bg-white text-gray-700 p-1 rounded-2xl shadow w-32 border border-gray-400"
                        onClick={() =>
                          navigate("/user-list", {
                            state: {
                              users: users,
                              organisation_name: state.name,
                            },
                          })
                        }
                      >
                        All Users
                      </button>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>

          {/*6D Admin */}
          {auth.roles[0] === "Administrator" && (
            <div>
              <h3 className="text-2xl font-semibold mb-4">Locations</h3>
              <div className="bg-white rounded-3xl shadow-lg p-4 mb-4">
                {isLocationsLoading && (
                  <div role="status" className="flex justify-center mb-4">
                    <svg
                      aria-hidden="true"
                      className="h-8 w-8 animate-spin fill-blue-600 text-gray-200 dark:text-gray-600"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                    <span className="sr-only">Loading...</span>
                  </div>
                )}
                {locations && locations.length > 0 ? (
                  locations.slice(0, 3).map((location) => (
                    <div className="flex items-center mb-4">
                      <img
                        src={`https://66-dementia.b-cdn.net/${env}/locations/images/${location.id}`}
                        alt="User"
                        className="w-12 h-12 rounded-full mr-4"
                      />
                      <div>
                        <h4 className="font-semibold">{location.name}</h4>
                      </div>
                      <button
                        className="ml-auto bg-gray-100 text-gray-700 p-1 rounded-3xl w-32 border border-gray-400"
                        onClick={() =>
                          navigate("/user-list", {
                            state: {
                              location_id: location.id,
                            },
                          })
                        }
                      >
                        View Details
                      </button>
                    </div>
                  ))
                ) : (
                  <p className="text-gray-600">
                    {!isLocationsLoading &&
                      "No Locations for this Organisation."}
                  </p>
                )}
                {locations && locations.length > 0 && (
                  <div className="flex justify-center">
                    <div className="flex justify-center">
                      <button
                        className="bg-white text-gray-700 p-1 rounded-2xl shadow w-32 border border-gray-400"
                        onClick={() =>
                          navigate("/location-list", {
                            state: {
                              organisation_id: state.id,
                              organisation_name: state.name,
                            },
                          })
                        }
                      >
                        View All
                      </button>
                    </div>
                  </div>
                )}
                <div className="flex justify-center mt-4">
                  <button
                    className="bg-green-600 text-white p-1 rounded-2xl shadow w-32 border border-gray-400"
                    onClick={() =>
                      navigate("/create-location", {
                        state: {
                          organisation_id: state.id,
                          organisation_name: state.name,
                        },
                      })
                    }
                  >
                    Create
                  </button>
                </div>
              </div>
            </div>
          )}

          {/* Organisational Admin */}
          {auth.roles[0] === "OrganisationAdministrator" && (
            <div>
              <h3 className="text-2xl font-semibold mb-4">Locations</h3>
              <div className="bg-white rounded-3xl shadow-lg p-4 mb-4">
                {isLocationsLoading && (
                  <div role="status" className="ml-[50%]">
                    <svg
                      aria-hidden="true"
                      className="h-8 w-8 animate-spin fill-blue-600 text-gray-200 dark:text-gray-600"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                    <span className="sr-only">Loading...</span>
                  </div>
                )}
                {locations &&
                  locations.slice(0, 3).map((item) => (
                    <div key={item.name} className="flex items-center mb-4">
                      <img
                        src={`https://66-dementia.b-cdn.net/${env}/locations/images/${item.id}`}
                        alt="Location"
                        className="w-12 h-12 rounded-full mr-4"
                      />
                      <div>
                        <h4 className="font-semibold">{item.name}</h4>
                      </div>
                      <button
                        className="ml-auto bg-gray-100 text-gray-700 p-1 rounded-3xl w-32 border border-gray-400"
                        onClick={() =>
                          navigate("/user-list", {
                            state: {
                              selectedLocation: item,
                              locations: locations,
                            },
                          })
                        }
                      >
                        View
                      </button>
                    </div>
                  ))}
                {!isLocationsLoading && (
                  <div className="flex justify-center">
                    <button
                      className="bg-white text-gray-700 p-1 rounded-2xl shadow w-32 border border-gray-400"
                      onClick={() =>
                        navigate("/teams", {
                          state: {
                            locations: locations,
                          },
                        })
                      }
                    >
                      All Locations
                    </button>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>

        <div className=" ml-80 mr-80 mb-8">
          <div>
            <h3 className="text-2xl font-semibold mb-4">Subscription</h3>
            <div className="bg-white rounded-3xl shadow-lg p-6">
              {isSubscriptionsLoading && (
                <div role="status" className="ml-[50%]">
                  <svg
                    aria-hidden="true"
                    className="h-8 w-8 animate-spin fill-blue-600 text-gray-200 dark:text-gray-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                  <span className="sr-only">Loading...</span>
                </div>
              )}
              {
                subscriptions && (
                  // subscriptions.map((subscription) => (
                  <div
                    key={subscriptions.id}
                    className="flex items-center mb-4"
                  >
                    <span className="flex-grow">
                      Expiry Date:{" "}
                      {new Date(subscriptions.expiry_date).toLocaleDateString()}
                    </span>
                    <span
                      className={`${
                        new Date(subscriptions.expiry_date) > new Date()
                          ? "bg-green-500"
                          : "bg-red-500"
                      } w-4 h-4 rounded-full`}
                    ></span>
                  </div>
                )
                // ))
              }
              {!isSubscriptionsLoading && subscriptions == null && (
                <div className="flex justify-center">
                  No Subscription.
                  {/* <button
                    className="bg-orange-500 text-white p-1 rounded-2xl w-32"
                    onClick={() =>
                      navigate("/subscriptions", {
                        state,
                      })
                    }
                  >
                    Subscribe
                  </button> */}
                </div>
              )}
            </div>
          </div>
        </div>
        {/* {isModalOpen && (
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
              <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full">
                {isMoving && (
                  <div role="status" className="ml-[50%]">
                    <svg
                      aria-hidden="true"
                      className="h-8 w-8 animate-spin fill-blue-600 text-gray-200 dark:text-gray-600"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                    <span className="sr-only">Loading...</span>
                  </div>
                )}
                {!isMoving && (
                  <>
                    <h2 className="text-xl font-bold mb-4">Confirm Delete</h2>
                    <p className="text-gray-600 mb-6">
                      Are you sure you want to delete this item? You have to
                      move users to other location before deleting location.
                    </p>
                    <div className="mb-4 w-3/4">
                      <label className="block text-gray-700">
                        Target Location:
                      </label>
                      <select
                        value={targetLocation}
                        onChange={(e) => {
                          setTargetLocation(e.target.value);
                        }}
                        className="w-full border p-2 rounded mt-1"
                      >
                        <option value="">Select</option>
                        {locations &&
                          locations.map((location) => (
                            <option key={location.id} value={location.id}>
                              {location.name}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="flex justify-end space-x-4">
                      <button
                        className="bg-gray-500 text-white py-2 px-4 rounded-lg hover:bg-gray-600"
                        onClick={closeModal}
                      >
                        Cancel
                      </button>
                      <button
                        className="bg-red-600 text-white py-2 px-4 rounded-lg hover:bg-red-700"
                        onClick={() => moveUsersLocation(selectedLocation)}
                      >
                        Move & Delete
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          )} */}

        <FooterComponent path="/customers" />
      </div>
    </div>
  );
};

export default OrganizationDashboardPage;
