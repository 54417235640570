import React, { useEffect, useState } from "react";
import {
  award,
  completedCourse,
  getUserCoursesByUser,
  getUserWebinarsByUser,
} from "../../apiFunction/ApiFunctions";
import { useLocation } from "react-router-dom";
import awardsIcon from "../../images/awardsIcon.png";
import HeaderComponent from "../admin/shared/HeaderComponent";
import FooterComponent from "../admin/shared/FooterComponent";

const UserProfile = () => {
  const location = useLocation();
  const { state } = location;
  const env = process.env.REACT_APP_ENV;
  const [isWebinarsLoading, setIsWebinarsLoading] = useState(true);
  const [isCoursesLoading, setIsCoursesLoading] = useState(true);
  const [webinars, setWebinars] = useState(null);
  const [activecourses, setActiveCourses] = useState(null);
  const [completedcourses, setCompletedCourses] = useState(null);
  const [awards, setAwards] = useState(null);
  const user = JSON.parse(sessionStorage.getItem("userdetails"));

  useEffect(() => {
    getUserCoursesByUser(state.user.id)
      .then((data) => {
        console.log("courses", data);
        setIsCoursesLoading(false);
        setActiveCourses(
          data.filter((course) => course.percent_complete < 100)
        );
        setCompletedCourses(
          data.filter((course) => course.percent_complete === 100)
        );
        setAwards(data.filter((course) => course.award_date !== null));
      })
      .catch((error) => {
        console.error("Error fetching courses", error);
      });

    getUserWebinarsByUser(state.user.id)
      .then((data) => {
        console.log("webinars", data);
        setIsWebinarsLoading(false);
        setWebinars(data);
      })
      .catch((error) => {
        console.error("Error fetching webinars", error);
      });
  }, []);

  // const courses = [
  //   {
  //     course_name: "6D Training for Teams",
  //     award_date: null,
  //     percent_complete: 80,
  //     score: null,
  //     completion_date: null,
  //     course_module_count: 4,
  //     course_image_url: null,
  //   },
  //   {
  //     course_name: "6D Specialist Team Training",
  //     award_date: null,
  //     percent_complete: 50,
  //     score: null,
  //     completion_date: null,
  //     course_module_count: 5,
  //     course_image_url: null,
  //   },
  //   {
  //     course_name: "6D Advanced Team Training",
  //     award_date: null,
  //     percent_complete: 30,
  //     score: null,
  //     completion_date: null,
  //     course_module_count: 6,
  //     course_image_url: null,
  //   },
  //   {
  //     course_name: "6D Training for Teams",
  //     award_date: "2024-09-26",
  //     percent_complete: 100,
  //     score: "95%",
  //     completion_date: "2024-09-26",
  //     course_module_count: 6,
  //     course_image_url: require("../../images/Image(2).png"),
  //   },
  //   {
  //     course_name: "6D Specialist Team Training",
  //     award_date: "2024-09-26",
  //     percent_complete: 100,
  //     score: "88%",
  //     completion_date: "2024-09-26",
  //     course_module_count: 7,
  //     course_image_url: require("../../images/Image(3).png"),
  //   },
  //   {
  //     course_name: "6D Advanced Team Training",
  //     award_date: null,
  //     percent_complete: 100,
  //     score: "98%",
  //     completion_date: "2024-09-26",
  //     course_module_count: 6,
  //     course_image_url: require("../../images/Image(4).png"),
  //   },
  //   {
  //     course_name: "6D Advanced Team Training 2",
  //     award_date: null,
  //     percent_complete: 100,
  //     score: "98%",
  //     completion_date: "2024-09-26",
  //     course_module_count: 6,
  //     course_image_url: require("../../images/Image(5).png"),
  //   },
  // ];

  // const awards = award();

  // const webinars = [
  //   {
  //     title: "Webinar on Introduction to",
  //     date: "5th April 2024",
  //     duration: "2 hours",
  //     status: "Registered",
  //   },
  //   {
  //     title: "Webinar on Dementia Care for Carers",
  //     date: "15th May 2024",
  //     duration: "3 hours",
  //     status: "Registered",
  //   },
  //   {
  //     title: "Webinar on Dementia Team Training",
  //     date: "20th June 2024",
  //     duration: "1.5 hours",
  //     status: "Registered",
  //   },
  // ];

  return (
    <div className="min-h-screen bg-white flex flex-col">
      <HeaderComponent />
      <div className="container mx-auto">
        <h2 className="text-3xl font-medium text-customBlue mb-8 ml-[-2%]">
          Overview for: {state.user.name}
        </h2>

        {/* Profile Card */}

        {/* <div className="flex-grow flex items-center justify-center p-6">
          <div className="bg-white shadow-lg rounded-3xl p-8 w-full max-w-md text-center">
            <img
              src={`https://66-dementia.b-cdn.net/${env}/users/pictures/${state.user.id}`}
              alt="Profile"
              className="w-24 h-24 mx-auto rounded-full shadow-md mb-4"
            />
            <h2 className="text-xl font-bold mb-2">{state.user.name}</h2>
            <p className="text-gray-600 font-medium mb-6">
              {state.user.roles[0]}
            </p>

            <select className="block w-full bg-gray-100 border border-gray-300 rounded-md py-2 px-3 mb-4 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500">
              <option>Choose Team or Location</option>
              <option>Belfast Branch</option>
              <option>Test Branch</option>
              <option>Location</option>
              <option>New Location</option>
            </select>

            <p className="text-gray-700 mb-1">
              Email:{" "}
              <a
                href="mailto:martin.johnston@6ddementia.org"
                className="text-blue-600"
              >
                {state.user.email_address}
              </a>
            </p>
            <p className="text-gray-700">
              Telephone: {state.user.phone_number}
            </p>
          </div>
        </div> */}

        {isCoursesLoading && (
          <div role="status" className="ml-[50%]">
            <svg
              aria-hidden="true"
              className="h-8 w-8 animate-spin fill-blue-600 text-gray-200 dark:text-gray-600"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
            <span className="sr-only">Loading...</span>
          </div>
        )}

        {!isCoursesLoading && (
          <>
            <div className="mb-8">
              <h3 className="text-xl font-medium mb-4 ml-[]">
                Completed Courses
              </h3>
              {completedcourses.length === 0 && (
                <h3 className="text-xl  mb-4 ml-[]">No Completed Course!</h3>
              )}
              {completedcourses.length > 0 && (
                <div className="grid grid-cols-1 md:grid-cols-4 gap-16">
                  {completedcourses &&
                    completedcourses.map((course, index) => (
                      <div
                        key={index}
                        className="bg-gray-200 shadow-md text-center rounded-xl overflow-hidden"
                      >
                        <img
                          src={`https://66-dementia.b-cdn.net/${env}/courses/images/${
                            course.course_id
                          }?${new Date().getTime()}`}
                          alt={course.course_name}
                          className="w-full h-48 object-cover p-6"
                        />
                        <div className="mb-4">
                          <h4 className="text-lg font-semibold">
                            {course.course_name}
                          </h4>
                          <p className="text-sm">{course.completion_date}</p>
                          <p className="text-sm">Score: {course.score}</p>
                          <p className="text-sm">
                            Modules: {course.course_module_count}
                          </p>
                        </div>
                      </div>
                    ))}
                </div>
              )}
            </div>

            <div className="mb-8 grid grid-cols-1 md:grid-cols-2 gap-8">
              <div>
                <h3 className="text-lg font-medium mb-4">
                  Active Training Courses
                </h3>
                {activecourses.length === 0 && (
                  <h3 className="text-lg mb-4">No Active Training Course!</h3>
                )}
                {activecourses.length > 0 &&
                  activecourses.map((course, index) => (
                    <div key={index} className="mb-4">
                      <div className="flex justify-between items-center">
                        <span>{course.course_name}</span>
                        <span>{course.percent_complete}% Complete</span>
                      </div>
                      <div className="w-full bg-gray-200 rounded-full h-2.5">
                        <div
                          className="bg-green-500 h-2.5 rounded-full"
                          style={{ width: `${course.percent_complete}%` }}
                        ></div>
                      </div>
                    </div>
                  ))}
              </div>

              <div>
                <h3 className="text-lg font-medium mb-4">
                  Awards & Certifications
                </h3>
                {awards.length === 0 && (
                  <h3 className="text-lg  mb-4">No Award & Certification!</h3>
                )}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
                  {awards.length > 0 &&
                    awards.map((course, index) => (
                      <div
                        key={index}
                        className="bg-white shadow-md rounded-lg p-4 flex flex-col items-center"
                      >
                        <div className="mb-2">
                          <img
                            src={awardsIcon}
                            alt="Webinar Thumbnail"
                            className="w-24 h-full object-cover"
                          />
                        </div>
                        <p className="text-center text-sm">
                          {course.award_date}
                        </p>
                        <p className="text-center text-sm font-semibold">
                          {course.course_name}
                        </p>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </>
        )}

        <div className="bg-gray-100 mb-8 p-6 flex justify-center rounded-xl items-center">
          <div className="w-full">
            <h1 className="text-2xl font-bold text-gray-800 mb-4">
              Webinars Registered by {state.user.name}
            </h1>
            {isWebinarsLoading && (
              <div role="status" className="ml-[50%]">
                <svg
                  aria-hidden="true"
                  className="h-8 w-8 animate-spin fill-blue-600 text-gray-200 dark:text-gray-600"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
                <span className="sr-only">Loading...</span>
              </div>
            )}
            {!isWebinarsLoading && webinars.length === 0 && (
              <h1 className="text-2xl  text-gray-800 mb-4">
                No Registered Webinar!
              </h1>
            )}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              {webinars &&
                webinars.map((webinar, index) => (
                  <div
                    key={index}
                    className="bg-gray-50 p-4 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-200 flex items-center"
                  >
                    <img
                      src={`https://66-dementia.b-cdn.net/${env}/webinars/images/${
                        webinar.webinar_id
                      }?${new Date().getTime()}`}
                      alt={webinar.webinar_title}
                      className="w-1/4 object-cover"
                    />
                    <div className=" ml-4">
                      <h2 className="text-lg font-semibold text-gray-800 mb-2">
                        {webinar.webinar_title}
                      </h2>
                      <p className="text-sm text-gray-600 mb-1">
                        Date: {webinar.webinar_date_time.split("T")[0]}
                      </p>
                      <p className="text-sm text-gray-600 mb-1">
                        Duration: {webinar.webinar_duration_minutes} minutes
                      </p>
                      <p className="text-sm text-gray-600">
                        Status: Registered
                      </p>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>

        <FooterComponent path={-1} state={state} />
      </div>
    </div>
  );
};

export default UserProfile;
